import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PropTypes from 'prop-types'
import GroupOrder from '../components/GroupOrder'
import SplitAddress from '../components/SplitAddress'
import WeatherCard from '../components/WeatherCard'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const Home = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Grid container justifyContent="center" sx={{ my: 5 }}>
          <Typography variant="h3">IceBear</Typography>
        </Grid>
        <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 4, px: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Nhóm đơn hàng" {...a11yProps(0)} />
            <Tab label="Tách địa chỉ" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <GroupOrder />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SplitAddress />
        </TabPanel>
        <Grid container justifyContent="center" sx={{ my: 2 }}>
          <Typography variant="caption">Powered by @toanlk 2021</Typography>
        </Grid>
        <WeatherCard />
      </Container>
    </>
  )
}

export default Home