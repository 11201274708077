import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import DriveFolderUploadOutlined from '@mui/icons-material/DriveFolderUploadOutlined'
import { styled } from '@mui/material/styles'

const Item = styled(Button)(({ theme }) => ({
  height: 180,
  width: '100%',
  border: '2px solid',
  borderStyle: 'dotted',
  '&:hover': {
    borderColor: '#0062cc',
    border: '2px',
    borderStyle: 'dotted',
  },
  borderRadius: '16px',
}))
const Input = styled('input')({
  display: 'none',
})

const FileUploader = ({ file, loading, onSelect }) => {
  const getColor = () => {
    return file ? 'primary' : 'action'
  }

  const handleSelectFile = (event) => {
    event.preventDefault()

    if (event.target && event.target.files) {
      const file = event.target.files[0]
      onSelect(file)
    }
    event.target.value = ''
  }

  const getFileContent = () => {
    if (loading) return 'Đang tải file...'

    return file ? (
      <Typography variant="subtitle1" sx={{ color: 'blue' }}>
        {file.name}
      </Typography>
    ) : (
      <Typography variant="h5" sx={{ color: 'gray' }}>
        Chọn file excel
      </Typography>
    )
  }

  return (
    <label htmlFor="contained-button-file">
      <Item variant="outlined" component="span">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 180 }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <DriveFolderUploadOutlined fontSize="large" color={getColor()} />
          )}
          {getFileContent()}
        </Grid>
        <Input
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          id="contained-button-file"
          type="file"
          onChange={handleSelectFile}
        />
      </Item>
    </label>
  )
}

export default FileUploader
