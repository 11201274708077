import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_BE_API_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
  },
})

const weatherApi = axios.create({
  baseURL: process.env.REACT_APP_WEATHER_API_URL,
  params: {
    appid: process.env.REACT_APP_WEATHER_API_KEY,
  },
})

const getCurrentWeather = async (lat, long) => {
  try {
    const { data } = await weatherApi.get('data/2.5/weather', {
      params: {
        lat,
        lon: long,
        units: 'metric',
      },
    })

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const splitAddress = async ({ address, isSpecial }) => {
  try {
    const { data } = await api.post('/address/', { address, isSpecial })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export { api, getCurrentWeather, splitAddress }
