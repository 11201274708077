import React, { useState } from 'react'
import * as xlsx from 'xlsx'
import lodash from 'lodash'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import Switch from '@mui/material/Switch'
import SendIcon from '@mui/icons-material/Send'
import FileUploader from './FileUploader'
import { splitAddress as apiSplitAddress } from '../utils'

const SplitAddress = () => {
  const [file, setFile] = useState(null)
  const [columns, setColumn] = useState([])
  const [selectedColumn, setSelectedColumn] = useState('')
  const [isSpecial, setIsSpecial] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [excelData, setExcelData] = useState(null)

  const handleChangeColumn = (event) => {
    setSelectedColumn(event.target.value)
  }

  const handleClickSelectFile = (selectedFile) => {
    setFile(selectedFile)
    const reader = new FileReader()
    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = xlsx.read(bstr, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = xlsx.utils.sheet_to_json(ws)
      setExcelData(data)
      const columns = Object.keys(data[0])

      setColumn(columns)
      setSelectedColumn(
        columns.includes('Address 3') ? 'Address 3' : columns[0],
      )
    }
    reader.readAsBinaryString(selectedFile)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleSpitAddress = async () => {
    if (!file || !selectedColumn) return
    setLoading(true)

    const fileName = file.name.split('.')[0]

    const address = excelData.map((item) => item[selectedColumn])
    const smallAddress = []

    while (address.length > 0) {
      smallAddress.push(address.splice(0, 20))
    }
    try {
      const arrayData = await Promise.all(
        smallAddress.map(async (item) => {
          const data = await apiSplitAddress({
            address: item,
            isSpecial,
          })
          return data
        }),
      )
      const data = lodash.flatten(arrayData)

      const workbook = xlsx.utils.book_new()
      const ws = xlsx.utils.json_to_sheet(data)
      xlsx.utils.book_append_sheet(workbook, ws, 'Sheet1')

      const newFileName = `${fileName}-split-address.xlsx`
      xlsx.writeFile(workbook, newFileName)

      setOpen(true)
      reset()
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  const reset = () => {
    setFile(null)
    setSelectedColumn('')
    setColumn([])
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <FileUploader
            loading={false}
            file={file}
            onSelect={handleClickSelectFile}
          />
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={12} alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={isSpecial}
                  onChange={() => setIsSpecial(!isSpecial)}
                  inputProps={{ 'aria-label': 'Special' }}
                />
              }
              label="Special"
            />
          </Grid>
          <Grid item xs={6} sx={{ pr: 2 }}>
            <FormControl size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-helper-label">
                Cột địa chỉ
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedColumn}
                label="Sheet"
                sx={{ borderRadius: 4 }}
                onChange={handleChangeColumn}
              >
                {columns.map((column) => (
                  <MenuItem value={column} key={column}>
                    {column}
                  </MenuItem>
                ))}
                {columns.length === 0 ? (
                  <MenuItem>Không có cột nào</MenuItem>
                ) : (
                  ''
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              sx={{ borderRadius: 4 }}
              startIcon={<SendIcon />}
              loading={loading}
              loadingPosition="start"
              onClick={() => handleSpitAddress()}
            >
              {loading ? 'Đang xử lí...' : 'Tách địa chỉ'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: '60%' }}
        >
          Tách địa chỉ thành công. File mới được lưu ở thư mục DownLoads!
        </Alert>
      </Snackbar>
    </>
  )
}

export default SplitAddress
