import { useAuth0 } from '@auth0/auth0-react'

const RequireAuth = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0()

  if (!isAuthenticated && isLoading === false) {
    loginWithRedirect()
  }

  return children
}

export default RequireAuth
