import React, { useState } from 'react'
import * as xlsx from 'xlsx'
import lodash from 'lodash'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'

import FileUploader from './FileUploader'

const GroupOrder = () => {
  const [file, setFile] = useState(null)
  const [sheets, setSheets] = useState([])
  const [selectedSheet, setSelectedSheet] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleSelectFile = (selectedFile) => {
    setFile(selectedFile)
    const reader = new FileReader()
    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = xlsx.read(bstr, { type: 'binary' })
      const wsname = wb.SheetNames
      setSheets(wsname)
      setSelectedSheet(wsname[0])
    }
    reader.readAsBinaryString(selectedFile)
  }

  const handleGroupOrder = () => {
    if (!file) return
    setLoading(true)

    const reader = new FileReader()
    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = xlsx.read(bstr, { type: 'binary' })
      const ws = wb.Sheets[selectedSheet]
      const data = xlsx.utils.sheet_to_json(ws)

      const groupData = lodash
        .chain(data)
        .groupBy('MÃ ĐƠN HÀNG')
        .map((value, key) => {
          let productAndQuanlity = ''
          value.forEach((item) => {
            productAndQuanlity =
              productAndQuanlity +
              'Mã ' +
              item['MÃ SẢN PHẨM'] +
              '-SL ' +
              item['SỐ LƯỢNG'] +
              ' '
          })

          return {
            'MÃ ĐƠN HÀNG': Number(key) ? Number(key) : key,
            'SẢN PHẨM': productAndQuanlity,
            XÃ: value[0]['XÃ'] || '',
            HUYỆN: value[0]['HUYỆN'] || '',
            TỈNH: value[0]['TỈNH'] || '',
          }
        })
        .value()

      // Create new file
      const fileName = file.name.split('.')[0]
      const workbook = xlsx.utils.book_new()
      const newWS = xlsx.utils.json_to_sheet(groupData)
      xlsx.utils.book_append_sheet(workbook, newWS, 'Sheet1')

      const newFileName = `${fileName}-group-order.xlsx`
      xlsx.writeFile(workbook, newFileName)

      setOpen(true)
      reset()
      setLoading(false)
    }

    reader.readAsBinaryString(file)
  }

  const reset = () => {
    setFile(null)
    setSheets([])
    setSelectedSheet('')
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center" sx={{ px: 0 }}>
        <Grid item xs={12}>
          <FileUploader
            loading={false}
            file={file}
            onSelect={handleSelectFile}
          />
        </Grid>

        <Grid item xs={12} container alignItems="center" spacing={3}>
          <Grid item xs={6} container>
            <FormControl size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-helper-label">
                Sheet
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Sheet"
                displayEmpty={true}
                value={selectedSheet}
                sx={{ borderRadius: 4 }}
                onChange={() => {}}
              >
                {sheets.map((sheet) => (
                  <MenuItem value={sheet} key={sheet}>
                    {sheet}
                  </MenuItem>
                ))}
                {sheets.length === 0 ? (
                  <MenuItem>Không có sheet nào</MenuItem>
                ) : (
                  ''
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SendIcon />}
              loading={loading}
              loadingPosition="start"
              sx={{ borderRadius: 4 }}
              onClick={() => handleGroupOrder()}
            >
              {loading ? 'Đang tạo file...' : 'Tạo file excel'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity="success"
          sx={{ width: '60%' }}
        >
          Tạo thành công. File mới được lưu trong thư mục Downloads!
        </Alert>
      </Snackbar>
    </>
  )
}

export default GroupOrder
