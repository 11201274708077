import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import SignIn from './pages/SignIn'
import RequireAuth from './components/RequireAuth'

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route path="signin" element={<SignIn />} />
      </Routes>
    </div>
  )
}

export default App
