import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Grow from '@mui/material/Grow'
import HomeIcon from '@mui/icons-material/WbSunnyTwoTone'
import IconButton from '@mui/material/IconButton'
import Chip from '@mui/material/Chip'

import cardBackground from '../assets/mountain.jpeg'
import { getCurrentWeather } from '../utils'

const WeatherCard = () => {
  const [weather, setWeather] = useState(null)
  const [show, setShow] = useState(false)
  const date = new Date()
  const weeks = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const getWeatherIcon = (icon) =>
    `http://openweathermap.org/img/wn/${icon}@2x.png`

  const capitalizeString = (str) => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
  }

  const handleShowCard = () => {
    setShow(!show)
  }

  const getWeatherData = async (lat, long) => {
    try {
      const data = await getCurrentWeather(lat, long)
      setWeather(data)
    } catch (error) {}
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const { coords } = position
        getWeatherData(coords.latitude, coords.longitude)
      },
      function (error) {
        console.error('Error Code = ' + error.code + ' - ' + error.message)
        getWeatherData(21.0294, 105.8544)
      },
    )
  }, [])

  return (
    <>
      <IconButton
        sx={{ position: 'absolute', top: '8px', left: '8px' }}
        onClick={handleShowCard}
      >
        <HomeIcon color="primary" fontSize="large" />
      </IconButton>

      <Grow
        in={show}
        style={{ transformOrigin: '0 0 0' }}
        {...(show ? { timeout: 1000 } : {})}
      >
        <Card
          sx={{
            backgroundImage: `url(${cardBackground})`,
            backgroundColor: 'rgba(0, 0, 0, 0.48)',
            backgroundBlendMode: 'overlay',
            width: 250,
            padding: 2,
            color: 'white',
            borderRadius: 2,
            backgroundSize: 'cover',
            position: 'absolute',
            top: '48px',
            left: '8px',
            ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.64)' },
          }}
        >
          <Grid container justifyContent="center">
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              {weeks[date.getDay()]}
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Typography variant="body">{date.getDate()}</Typography>
          </Grid>

          <Grid container justifyContent="center">
            <img
              alt="weather"
              width={'80px'}
              filter={'drop-shadow(0 0 4px white)'}
              src={getWeatherIcon(weather?.weather[0].icon || '03d')}
            />
          </Grid>
          <Grid container justifyContent="center">
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              {weather ? weather.name : ''}
            </Typography>
            <Chip label="VN" color="warning" size="small" />
          </Grid>
          <Grid container justifyContent="center">
            <Typography variant="body">
              {capitalizeString(weather?.weather[0].description || '')}
            </Typography>
          </Grid>

          <Grid
            container
            px={1}
            py={1}
            mt={2}
            sx={{
              borderRadius: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.36)',
            }}
          >
            <Grid item container direction="column" xs={4}>
              <Grid item container justifyContent="center">
                <Typography variant="caption">Current Temp</Typography>
              </Grid>
              <Grid item container justifyContent="center">
                <Typography variant="body" sx={{ fontWeight: 700 }}>
                  {Math.round(weather?.main.temp || 0)} ºC
                </Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={4}>
              <Grid item container justifyContent="center">
                <Typography variant="caption">Feels Like</Typography>
              </Grid>
              <Grid item container justifyContent="center">
                <Typography variant="body" sx={{ fontWeight: 700 }}>
                  {Math.round(weather?.main.feels_like || 0)} ºC
                </Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={4}>
              <Grid item container justifyContent="center">
                <Typography variant="caption">Humidity</Typography>
              </Grid>
              <Grid item container justifyContent="center">
                <Typography variant="body" sx={{ fontWeight: 700 }}>
                  {Math.round(weather?.main.humidity || 0)}%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grow>
    </>
  )
}

export default WeatherCard
